import React, { useEffect, useState } from 'react';
import { DimensionValue, View, TouchableOpacity } from 'react-native';
import { Transition } from 'react-transition-group';

import { useTheme } from '@rugby-au/theme';
import { getContainerStyles, getButtonStyles } from './styles';
import { useAppConfig } from '@rugby-au/app-config';
import { Text } from '@rugby-au/commons';

const duration = 300;

const defaultErrorMessage = { severity: '', message: '' };

export const NotificationBar = () => {
  const [inProp, setInProp] = useState(false);
  const [top, setTop] = useState<DimensionValue | undefined>('-50%');

  const { notification, setNotification } = useAppConfig();
  const { spacing, typography, colors } = useTheme();

  useEffect(() => {
    let current: NodeJS.Timeout;
    if (notification?.message) {
      setInProp(true);
      setTop(0);

      current = setTimeout(() => {
        setTop('-50%');
        setNotification(defaultErrorMessage);
      }, 12000);
      //clearTimeout(current);
    } else {
      setTop('-50%');
      setInProp(false);
    }
    return () => {
      if (current) {
        clearTimeout(current);
      }
    };
  }, [notification, setNotification]);

  const handleClose = () => {
    setTop('-50%');
    setNotification(defaultErrorMessage);
  };

  const styles = getContainerStyles({ duration, severity: notification?.severity ?? '', spacing, typography, colors });
  const buttonStyles = getButtonStyles({ spacing, colors });

  return (
    <Transition in={inProp} timeout={duration}>
      <View style={[styles.container, { top: top }]}>
        <Text style={{ color: colors.primaryText }}>{notification?.message ?? ''}</Text>
        <TouchableOpacity style={buttonStyles.button} onPress={handleClose}>
          <Text style={buttonStyles.buttonText}>X</Text>
        </TouchableOpacity>
      </View>
    </Transition>
  );
};
