import { useTheme } from '@rugby-au/theme';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Text, View } from 'react-native';
import { CountryList, CountryType } from './assets/Countries';
import { PickerInputField } from './pickers/PickerInputField';
import { getPhoneInputStyles } from './styles';
import { TextInputField, TextInputFieldProps } from './TextInputField';
import { FieldRefMethodProps } from './types';
import { autoValidateIn } from './utils';
import parsePhoneNumber, { isValidPhoneNumber, CountryCode } from 'libphonenumber-js';
import { useAppConfig } from '@rugby-au/app-config';

export interface PhoneInputFieldProps extends TextInputFieldProps {
  /** default phone country */
  label?: string;
  value?: string;
  defaultCountry?: string;
  onChangeField?: (value: string) => void;
  disabled?: boolean;
}

export const PhoneInputField = ({ defaultCountry = 'AU', label, value, onChangeField, disabled = false, ...props }: PhoneInputFieldProps) => {
  // Keeping a seperate state for the value to avoid react rendering delays
  const { nationalId, nationalConfig } = useAppConfig();
  const [inputValue, setInputValue] = useState('');
  const [country, setCountry] = useState<CountryType>();
  const [defCountry, setDefCountry] = useState(nationalConfig?.config?.general?.locale?.toUpperCase() ?? defaultCountry);
  const [error, setError] = useState('');
  const [textHasChanged, setTextHasChanged] = useState(false);
  const [countryHasChange, setCountryHasChanged] = useState(false);
  const textTimerRef = useRef<NodeJS.Timeout | null>(null);
  const countryTimerRef = useRef<NodeJS.Timeout | null>(null);
  const { colors, spacing, typography } = useTheme();
  let textRef = useRef<FieldRefMethodProps>(null);
  let countryRef = useRef<FieldRefMethodProps>(null);

  useEffect(() => {
    if (nationalConfig?.config?.general?.locale) {
      let _country = CountryList.find(item => item.value === nationalConfig?.config?.general?.locale?.toUpperCase() ?? defaultCountry);
      setCountry(_country);
      setDefCountry(nationalConfig.config.general.locale.toUpperCase());
    }
  }, [nationalId, nationalConfig, defaultCountry]);
  // On init assign default input value to state
  useEffect(() => {
    if (inputValue !== value && inputValue === '') {
      if (value) {
        let _value: string | undefined = value;
        _value = _value.replace(/[^\w\s|+]/gi, '');
        let _country;
        // if (_value?.startsWith('+')) {
        let phoneNumber = parsePhoneNumber(_value);
        if (phoneNumber) {
          _value = phoneNumber?.nationalNumber.toString();
          _country = CountryList.find(c => {
            return c.value === phoneNumber?.country;
          });
        } else {
          if (!country) {
            _country = CountryList.find(c => c.value === defCountry);
          }
          phoneNumber = parsePhoneNumber(_value, { defaultCountry: _country?.value as CountryCode });
          if (phoneNumber) {
            _value = phoneNumber?.nationalNumber.toString();
          }
        }
        // }
        if (!_country) {
          _country = CountryList.find(c => c.value === defCountry);
        }
        setCountry(_country);
        setDefCountry(_country ? _country.value : '');
        setInputValue(_value ?? '');
      }
      // else if (defCountry) {
      //   console.log('PhoneInputField.tsx line 80 - defCountry ', defCountry);
      //   // if (!country) {
      //   let _country = CountryList.find(item => item.value === defCountry);
      //   setCountry(_country);
      //   setDefCountry(_country ? _country.value : '');
      //   // }
      // }
    }
    // else if (defCountry) {
    //   if (!country) {
    //     let _country = CountryList.find(item => item.value === defCountry);
    //     setCountry(_country);
    //   }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, value, defaultCountry]);

  const validate = useCallback(async () => {
    if (!props.required) {
      return true;
    }
    let isValid = true;
    setError('');
    isValid = isValidPhoneNumber(textRef?.current?.value, country?.value as CountryCode);
    if (!isValid) {
      setError('Invalid phone number');
    }
    return isValid;
    // }, [validateCountry, validateText]);
  }, [country?.value, props.required]);

  const validateText = useCallback(async () => {
    if (!(await textRef?.current?.validate()) || !(await validate())) {
      return false;
    }
    return true;
  }, [validate]);

  const validateCountry = useCallback(async () => {
    if (!(await countryRef?.current?.validate())) {
      return false;
    }
    return true;
  }, []);

  //if autoValidation is true then the component will be reactive validating every XXX millisencons
  useEffect(() => {
    if (props.autoValidate && countryHasChange) {
      countryTimerRef.current = setTimeout(() => {
        validateCountry();
      }, autoValidateIn);
    }
    return () => {
      if (countryTimerRef.current) {
        clearTimeout(countryTimerRef.current);
      }
    };
  }, [countryHasChange, country, props.autoValidate, validateCountry]);

  useEffect(() => {
    if (props.autoValidate && textHasChanged) {
      textTimerRef.current = setTimeout(() => {
        validateText();
      }, autoValidateIn);
    }
    return () => {
      if (textTimerRef.current) {
        clearTimeout(textTimerRef.current);
      }
    };
  }, [textHasChanged, inputValue, props.autoValidate, validateText]);

  useEffect(() => {
    if (countryHasChange || textHasChanged) {
      validateText();
    }
  }, [country, countryHasChange, textHasChanged, validateText, inputValue]);

  useEffect(() => {
    props.fieldRef.current = {
      validate: validate,
      value: (() => {
        const phoneNumber = parsePhoneNumber(inputValue, country?.value as CountryCode);
        return phoneNumber?.number;
      })(),
    };
  }, [country?.value, inputValue, props.fieldRef, validate]);

  const textHandleOnChange = useCallback(
    (text: string) => {
      text = text.replace(/[^\w\s]/gi, '');
      setInputValue(text);
      let phone = country?.dialvalue + text;
      setTextHasChanged(true);
      onChangeField && onChangeField(phone);
    },
    [country?.dialvalue, onChangeField],
  );
  const countryHandleOnChange = (_country: any) => {
    setCountry(_country);
    setCountryHasChanged(true);
  };

  const { styles } = getPhoneInputStyles({ colors, spacing, typography });

  return (
    <>
      {label && (
        <Text style={{ ...styles.label, ...props.labelStyles }}>
          {label} {props.required && '*'}
        </Text>
      )}
      <View style={styles.fieldContainer}>
        <View style={styles.picker}>
          <PickerInputField
            fieldRef={countryRef}
            items={CountryList}
            emptyValue={'🏳️'}
            //containerStyles={styles.pickerContainer}
            concatRenderProp={'en'}
            value={defCountry}
            onChangeField={countryHandleOnChange}
            required={true}
            editable={props.editable ?? true}
            disabled={disabled}
            {...{ autoComplete: 'tel-country-code' }}
          />
        </View>
        <View style={styles.text}>
          <TextInputField
            {...props}
            autoValidate={false}
            fieldRef={textRef}
            type={'tel'}
            value={inputValue}
            placeholder={country?.mask}
            onChangeField={textHandleOnChange}
            disabled={disabled}
            autoCompleteTypeWeb={'tel-local'}
            showError={false}
            prefix={country?.dialvalue}
          />
        </View>
      </View>
      <Text style={styles.error}>{error}</Text>
    </>
  );
};
