import AsyncStorage from '@react-native-async-storage/async-storage';

import { get, post, put } from './API';
import { getItem, removeItem, setItem } from './localStorage';
import { ClientAuthProps } from './client';

export type Credentials = {
  email: string;
  password: string;
};

export const getUser = async () => {
  try {
    return await getItem('user');
  } catch (error) {
    return;
  }
};

export const clearUser = async () => {
  try {
    await setItem('user', null);
  } catch (error) {
    console.log('file: user.ts:35 ~ clearUser ~ error:', error);
  }
  return;
};

export const loginWithAuthCode = async ({ authCode, clientId, headers }: { authCode: string; clientId: string; headers: any }) => {
  return post({
    params: { authCode, clientId },
    path: 'rau/auth/v2/loginWithAuthCode',
    headers,
  });
};

//Remove from storage when logging out a user
//TODO confirm with Torres
export const logout = async ({ clientId }: { clientId?: string }) => {
  const response = await post({
    path: 'rau/api/v2/logout',
    useJwtToken: true,
    skipToApiToken: true,
    params: { clientId },
  });
  await AsyncStorage.clear();
  return response;
};

export const getCurrentProfile = async () => {
  const user = await getItem('user');
  if (!user) {
    return;
  }
  return await get({ path: `rau/api/v1/myprofile/${user?.userId}`, useUserToken: true });
};

export const getProfile = async (profile: any, type: string = 'email') => {
  return await get({
    params: profile,
    path: `rau/auth/v2/profile/?type=${type}`,
    encodedParams: ['email'],
  });
};

export const getProfileByEmail = async (email: string) => {
  return await get({
    params: { email },
    path: 'rau/auth/v1/profileByEmail',
    encodedParams: ['email'],
  });
};

export const checkToken = async (client?: ClientAuthProps) => {
  try {
    const user = await getCredentials();
    let headers;
    if (user?.token) {
      if (client) {
        headers = {
          clientId: client.username,
          codeChallenge: client.codeChallenge,
        };
      }
      const response = await post({
        path: 'rau/api/v1/auth/checkToken/',
        useJwtToken: true,
        headers,
        params: { samlRequest: client?.SAMLRequest },
      });
      if (!response.error) {
        user.authCode = response.authCode;
        user.redirectUrl = response.redirectUrl;
        user.SAMLResponse = response.SAMLResponse;
      } else {
        return response;
      }
      return user;
    } else {
      return;
    }
  } catch (error) {
    return;
  }
};

export const refreshToken = async (client?: ClientAuthProps) => {
  try {
    const user = await getCredentials();
    let headers;
    if (user?.token) {
      if (client) {
        headers = {
          clientId: client.username,
          codeChallenge: client.codeChallenge,
        };
      }
      const response = await post({
        path: 'rau/api/v1/auth/refreshToken/',
        useJwtToken: true,
        headers,
      });
      if (!response.error) {
        console.log('file: user.ts:119 ~ refreshToken ~ response:', response);
        user.jwtTokens.token = response.jwtTokens.token;
        user.token = response.token;
        await setCredentials(user);
        return true;
      } else {
        console.error('file: user.ts:124 ~ refreshToken ~ response.error:', response.error);
        return false;
      }
    } else {
      return false;
    }
  } catch (error) {
    console.error('file: user.ts:130 ~ refreshToken ~ error:', error);
    return false;
  }
};

export const getCredentials = async (): Promise<any> => {
  return await getItem('user');
};

export const setCredentials = async (user: any): Promise<any> => {
  if (user) {
    return await setItem('user', user);
  } else {
    return await removeItem('user');
  }
};

export const recover = async ({ account, type, nationalId }: { account: any; nationalId: string; type: string }) => {
  return await post({
    params: account,
    path: `rau/auth/v2/profile/recover/?type=${type}`,
    headers: { nationalId, clientId: account.clientId },
  });
};

export const reset = async (account: { email: string; type: string }) => {
  return await post({ params: account, path: 'rau/auth/v2/account/reset' });
};

export const create = async ({ account, client, nationalId }: { account: any; client: ClientAuthProps; nationalId: string }) => {
  if (client) {
    account.clientId = client.username;
    account.codeChallenge = client.codeChallenge;
    account.redirectPath = client.redirectPath;
  }
  return await post({
    params: account,
    path: 'rau/auth/v2/profile/',
    encodedParams: ['firstname', 'lastname', 'mobile', 'email', 'birthdate', 'nationals'],
    headers: { nationalId },
  });
};

export const updated = async (account: any) => {
  const user = await getItem('user');
  return await put({
    params: account,
    path: `rau/auth/v2/profile/${user.userId}`,
    useJwtToken: true,
    encodedParams: ['firstname', 'lastname', 'mobile', 'gender', 'address'],
  });
};
