import NetInfo from '@react-native-community/netinfo';
import { getItem, setItem } from './localStorage';
const forge = require('node-forge');

export const loadBasicAuthHeaders = (authheader: string) => {
  const encodedString = Base64.btoa(authheader);
  const auth = `Basic ${encodedString}`;
  const headers = { Authorization: auth };
  return headers;
};

export const loadBearerAuthHeaders = (token: string) => {
  const auth = `Bearer ${token}`;
  const headers = { Authorization: auth };
  return headers;
};
const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
export const Base64 = {
  btoa: (input: string = '') => {
    let str = input;
    let output = '';

    for (let block = 0, charCode, i = 0, map = chars; str.charAt(i | 0) || ((map = '='), i % 1); output += map.charAt(63 & (block >> (8 - (i % 1) * 8)))) {
      charCode = str.charCodeAt((i += 3 / 4));

      if (charCode > 0xff) {
        throw new Error("'btoa' failed: The string to be encoded contains characters outside of the Latin1 range.");
      }
      block = (block << 8) | charCode;
    }
    return output;
  },
  atob: (input: string = '') => {
    let str = input.replace(/[=]+$/, '');
    let output = '';

    if (str.length % 4 == 1) {
      throw new Error("'atob' failed: The string to be decoded is not correctly encoded.");
    }
    for (
      let bc = 0, bs = 0, buffer, i = 0;
      (buffer = str.charAt(i++));
      ~buffer && ((bs = bc % 4 ? bs * 64 + buffer : buffer), bc++ % 4) ? (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6)))) : 0
    ) {
      buffer = chars.indexOf(buffer);
    }
    return output;
  },
};

const networkStatus = async () => {
  NetInfo.configure({
    reachabilityUrl: 'https://api.rugbyxplorer.com.au/rau/api/v1/myprofile/testConnection',
    reachabilityTest: async response => response.status === 401,
    reachabilityLongTimeout: 60 * 1000, // 60s
    reachabilityShortTimeout: 5 * 1000, // 5s
    reachabilityRequestTimeout: 15 * 1000, // 15s
    reachabilityShouldRun: () => true,
    shouldFetchWiFiSSID: false, // met iOS requirements to get SSID. Will leak memory if set to true without meeting requirements.
    useNativeReachability: false,
  });
  const status = await (await NetInfo.refresh()).isConnected;
  console.log('utils.tsx line 38 - status ', status);
  return status;
};

export const initialState = {
  status: 'idle',
  error: null,
  data: [],
};

export const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'FETCHING':
      return { ...initialState, status: 'fetching' };
    case 'FETCHED':
      return { ...initialState, status: 'fetched', data: action.payload };
    case 'FETCH_ERROR':
      return { ...initialState, status: 'error', error: action.payload };
    default:
      return state;
  }
};

export const offline: Function = async (wrapped: Function) => {
  return async () => {
    try {
      // await AsyncStorage.clear();
      let item;
      const key = JSON.stringify({ name: wrapped.name /*,arguments*/ });
      const netStatus = await networkStatus();
      if (netStatus) {
        //@ts-ignore;
        item = wrapped.apply(this, arguments);
        await setItem(key, item);
      } else {
        item = await getItem(key);
      }
      // item = await getItem(key);
      return item;
    } catch (e) {
      console.log('utils.tsx line 77 - e ', e);
    }
  };
};

const importPublicKey: Function = async (pemPublicKey: string) => {
  const publicKey = forge.pki.publicKeyFromPem(pemPublicKey);

  return publicKey;
};

// const importPublicKey: Function = async (pemPublicKey: string) => {
//   // Convert PEM to ArrayBuffer
//   const pemString = pemPublicKey.replace(/-----BEGIN PUBLIC KEY-----|-----END PUBLIC KEY-----|\s/g, '');
//   const pemBytes = atob(pemString);
//   const buffer = new ArrayBuffer(pemBytes.length);
//   const view = new Uint8Array(buffer);
//   for (let i = 0; i < pemBytes.length; i++) {
//     view[i] = pemBytes.charCodeAt(i);
//   }
//   // Import the public key
//   const publicKey = await window.crypto.subtle.importKey(
//     'spki', // Key format
//     buffer, // Key data
//     {
//       name: 'RSA-OAEP',
//       hash: { name: 'SHA-256' },
//     },
//     true, // Extractable
//     ['encrypt'], // Key usage
//   );

//   return publicKey;
// };

export const encryptData: Function = async (data: any) => {
  // console.log('utils.ts line 139 - data ', data);
  try {
    const pemPublicKey = process.env.NEXT_PUBLIC_RX_PUBLIC_KEY?.replace(/\\n/g, '\n');

    // Parse the PEM public key
    const publicKey = forge.pki.publicKeyFromPem(pemPublicKey);

    const toEncode = JSON.stringify(data);
    const dataBuffer = Buffer.from(toEncode, 'utf8');

    // Encrypt the data
    const encryptedData = publicKey.encrypt(dataBuffer.toString('binary'), 'RSA-OAEP', {
      md: forge.md.sha256.create(),
    });

    const encryptedDataBase64 = forge.util.encode64(encryptedData);
    return encryptedDataBase64;
  } catch (e) {
    console.log('utils.ts line 152 - e ', e);
    return '';
  }
};
export const encryptData_window: Function = async (data: any) => {
  const pemPublicKey = process.env.NEXT_PUBLIC_RX_PUBLIC_KEY?.replace(/\\n/g, '\n');

  const publicKey = await importPublicKey(pemPublicKey);
  const encoder = new TextEncoder();
  const toEncode = JSON.stringify(data);
  //const toEncode = typeof data === 'string' ? data : JSON.stringify(data);
  const dataBuffer = encoder.encode(toEncode);

  // Encrypt the data
  const encryptedData = await window.crypto.subtle.encrypt(
    {
      name: 'RSA-OAEP',
    },
    publicKey,
    dataBuffer,
  );
  const encryptedDataArray = Array.from(new Uint8Array(encryptedData));
  const encryptedDataBase64 = window.btoa(String.fromCharCode.apply(null, encryptedDataArray));
  //console.log('Encrypted Data:', encryptedDataBase64);
  return encryptedDataBase64;
};

export const encryptObject: Function = async (data: { [key: string]: any }) => {
  console.log('utils.ts line 147 - data ', data);
  for (const key in data) {
    if (typeof data[key] === 'object') {
      data[key] = await encryptObject(data[key]);
    } else {
      data[key] = await encryptData(data[key]);
    }
  }
  return data;
};

export const encodeParamsFromPath = (path: string) => {
  if (path === '/' || path === '') {
    return path;
  }
  let urlParams = '';
  new URLSearchParams(path).forEach((value, key) => {
    urlParams += `&${key}=${encodeURIComponent(value as string)}`;
  });
  urlParams = urlParams.substring(1);
  return urlParams.startsWith('/') ? urlParams : `/${urlParams}`;
};

export const encodeParamsFromQuery = (query: Record<string, string | string[] | undefined>) => {
  let urlParams = '';
  for (const key in query) {
    urlParams += `&${key}=${encodeURIComponent(query[key] as string)}`;
  }
  return urlParams.substring(1);
};

export const addEncodedParams = (path: string, queryParams: Record<string, string | string[] | undefined>) => {
  let urlParams = encodeParamsFromQuery(queryParams);
  if (urlParams) {
    path += path.includes('?') ? `&${urlParams}` : `?${urlParams}`;
  }
  return path;
};

export const createRedirectUrl = (
  redirectUrl: string,
  redirectPath: string,
  authCode: string,
  clientId: string,
  queryParams: Record<string, string | string[] | undefined>,
  noPkce: boolean,
  token: string,
) => {
  if (queryParams.redirectPath) {
    redirectPath = decodeURIComponent(redirectPath as string);
  }

  if (authCode) {
    if (noPkce) {
      authCode += `^|^.u6YT^${token}`;
    }
    redirectUrl += `${redirectUrl.includes('?') ? '&' : '?'}authCode=${authCode}&clientId=${clientId}`;
  }

  if (redirectPath) {
    redirectUrl = redirectUrl?.substring(redirectUrl.length - 1) === '/' ? redirectUrl?.substring(0, redirectUrl.length - 1) : redirectUrl;
    redirectUrl = `${redirectUrl}${encodeParamsFromPath(redirectPath)}`;
  }

  return redirectUrl;
};
